/* eslint react-hooks/exhaustive-deps: 0 */


import moment from "moment";
import { Col, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { officeDevice, syncClock, offlineDevice, syncOn, onlineLog, offlineLog } from "../../../assets/images";
import { removeCurrentStep, removeProcessState, selectCurrentAppState, setCurrentStep, setIsPaused, setPausedData, setProcessState } from "../../../redux/features/app/appSlice";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { usePauseProcessMutation, useResumeProcessMutation, useStartProcessMutation, useStopActiveProcessMutation } from "../../../redux/services/processSlice";
import { UIbutton, UIsecondaryButton } from "../../common/button";
import { ModalButton, ModalPrimaryText, ModalType, WebsocketKey } from "../../../config/enum";
import { UIConfirmModal } from "../../common/confirmModal";
import { useGetDeviceStatusByMacQuery } from "../../../redux/services/deviceSite";
import { compareIcon, dataSyncIcon, gpio, gpioSound, notifyIcon, printLabel, scanTag, waitIcon } from "../../../assets/icons";
import { UIImage } from "../../common/image";
import { ProcessName } from "../../../config/constants";
import { socket } from "../../../redux/services/webSocket";
import { Page } from "../../../routes/config";
import { ProcessPausedModal } from "../../common/pausedModal";
export const DeviceDetail = (props: any) => {
  const { data,setSyncedData,syncedData, loadingBar, setLoadingBar } = props;
  let beforeStep = data?.data?.steps
    ? data?.data?.steps
    : data?.data?.processData[0]?.steps;
  const processName = data?.data?.processName;
  const processId = data?.data?.processId;
  const deviceId = data?.data?.deviceId;
  let steps = beforeStep
    ? typeof beforeStep[0] === "string"
      ? JSON.parse(beforeStep)
      : beforeStep
    : [];

  const startLoop = parseInt(data?.data?.startLoop) || 0;
  const { processState } = useSelector(selectCurrentAppState);

  const [currentProcessState, setCurrentProcessState] = useState<any>({})
  let navigate = useNavigate();
  const { currentStep, isPausedCompleted, pausedData } =
    useSelector((state: any) => state.app);
  const { user,  tenantId } = useSelector(authStateSelector);
  const loopActionPosition = startLoop ? startLoop : 0;
  const [stepIndex, setStepIndex] = useState(0);
  const distance = startLoop;
  const [startProcess, setStartProcess] = useStartProcessMutation();
  const [stopProcess, setStopProcess] = useStopActiveProcessMutation();
  const [pauseProcess, pauseProcessInfo] = usePauseProcessMutation();
  const [resumeProcess, resumeProcessInfo] = useResumeProcessMutation();
  const [exitModal, setExitModal] = useState(false);
  const [pauseModal, setPauseModal] = useState<any>()
  let x: any;


  useEffect(()=>{
console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.", `${data?.data?.processId} ${data?.data?.deviceId}`)
    setCurrentProcessState(processState[`${data?.data?.processId} ${data?.data?.deviceId}`])
  }, [processState])

  if (processName != ProcessName.CHECK_STOCK) {
    x = setInterval(() => {
      if (stepIndex < steps?.length - 1) {
        // setStepIndex(stepIndex + 1);
        dispatch(setCurrentStep({processId: data?.data?.processId, deviceId: data?.data?.deviceId, step: stepIndex + 1}));
      } else {
        // setStepIndex(loopActionPosition);
        dispatch(setCurrentStep({processId: data?.data?.processId, deviceId: data?.data?.deviceId, step: loopActionPosition}));
      }
      clearInterval(x);
    }, 1500);
  } else {
    socket.on(`${WebsocketKey.CHECK_REF_STOCK}-${data?.data?.deviceId}-${data?.data?.processId}`, (wsdata) => {
      console.log("DATA Recieved for state change", wsdata);
      if (
        processId == wsdata?.data?.processId &&
        deviceId == wsdata?.data?.deviceId
      ) {
        let stateSeq = steps?.findIndex(
          (step: any) => step?.name == wsdata?.state
        );
        dispatch(setCurrentStep({processId: data?.data?.processId, deviceId: data?.data?.deviceId, step: stateSeq}));
      }
    }); 
  }

  useEffect(() => {
    let step = currentStep[`${data?.data?.processId} ${data?.data?.deviceId}`]
    setStepIndex(step);
  }, [currentStep]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setCurrentStep({processId: data?.data?.processId, deviceId: data?.data?.deviceId, step: 0}));
  //   };
  // }, []);

  const stepProcess: any = {
    "Scan Tag": scanTag,
    "GPIO Light": gpio,
    "GPIO Sound": gpioSound,
    "Print Label": printLabel,
    "Scan Multiple": compareIcon,
    "Data Sync": dataSyncIcon,
    Notify: notifyIcon,
    Wait: waitIcon,
  };
  console.log("data process ", data, startLoop);

  const getDeviceStatusByMac = useGetDeviceStatusByMacQuery(
    { macDevice: data?.data?.mac || data?.data?.macAddress },
    { refetchOnMountOrArgChange: true, pollingInterval: 3000 }
  );

  const onStartClick = () => {
    startProcess({
      userId: user?.userId,
      processId: data?.data?.processId,
      deviceId: data?.data?.deviceId,
    });
  };

  const dispatch = useDispatch();

  // activity logs button
  useEffect(() => {
    if (setStartProcess?.isError) {
      setLoadingBar(false)
      message.error({
        content: setStartProcess?.error?.data?.error?.message,
        key: "notificationKey",
        duration: 2,
        className: "errorMessage",
      });
    }else if(setStartProcess?.isLoading) {
      setLoadingBar(true)
    }else if(setStartProcess?.isSuccess) {
      setLoadingBar(false)

    }
  }, [setStartProcess?.isError, setStartProcess?.isSuccess, setStartProcess?.isLoading]);
  console.log(setStartProcess?.isError, "processid");

  const onStopClick = () => {
    // 
    stopProcess({
      stopBy: user?.userId,
      processId: data?.data?.processId,
      processName: data?.data?.processName,
      deviceId: data?.data?.deviceId,
      type: "exit",
      activity_logs: true,
      userId: user?.userId,
    });
  };

  // useEffect(() => {
  //   if (!setIsPaused) {
  //     message.success("Process has been paused successfully");
  //   }
  // }, [setIsPaused]);

  const onPauseConfirmClick = () => {
    console.log("DISPATCHING 9")
    setPauseModal(false)
    dispatch(setProcessState({
      processId: data?.data?.processId,
      deviceId: data?.data?.deviceId,
      state: "pause-processing",
  }))
    pauseProcess({
      userId: user?.userId,
      processId: data?.data?.processId,
      deviceId: data?.data?.deviceId,
      type: "pause",
    });
  };

  const onPauseClick = () => {
    setPauseModal(true)
  }

  const onResumeClick = () => {
    resumeProcess({
      userId: user?.userId,
      processId: data?.data?.processId,
      deviceId: data?.data?.deviceId,
      type: "pause",
      workflowName: data?.data?.workflowName,
    });
  };


  console.log("STATE OF PROCESSES", processState, currentProcessState)

  useEffect(() => {
    if (setStartProcess?.isSuccess) {
      console.log("DISPATCHING 1")
      dispatch(setProcessState({
        processId: data?.data?.processId,
        deviceId: data?.data?.deviceId,
        state: "pause",
        
    }))
      // message.success({
      //   content: "Process Started Successfully",
      //   key: "notificationKey",
      //   className: "successMessage",
      //   duration: 2,
      // });
    } else if (resumeProcessInfo?.isSuccess) {
      console.log("DISPATCHING 2")

      dispatch(setProcessState({
        processId: data?.data?.processId,
        deviceId: data?.data?.deviceId,
        state: "pause",
        
    }))
      message.success({
        content: "Process Resumed Successfully",
        key: "notificationKey",
        className: "successMessage",
        duration: 2,
      });
    }
  }, [setStartProcess?.isSuccess, resumeProcessInfo?.isSuccess]);

  useEffect(() => {
    if (pauseProcessInfo?.isSuccess) {
      console.log("DISPATCHING 3")

      dispatch(setProcessState({
        processId: data?.data?.processId,
        deviceId: data?.data?.deviceId,
        state: "pause-processing",
        isPauseModalVisible: true
    }))
    }
    console.log(">>>>>>>>>>>>>>>> API SUCCESS")
  }, [pauseProcessInfo?.isSuccess]);

  useEffect(() => {
    if (setStopProcess?.isSuccess) {
      console.log("DISPATCHING 4")
      dispatch(removeProcessState({
      processId: data?.data?.processId,
      deviceId: data?.data?.deviceId,
    }))
    dispatch(removeCurrentStep({
      processId: data?.data?.processId,
      deviceId: data?.data?.deviceId,
    }))
      message.success({
        content: "Process Stopped Successfully",
        key: "notificationKey",
        duration: 2,
        className: "successMessage",
      });
      navigate(`../executeprocess`);
      // dispatch(setTableData([]));
      setSyncedData({});
    }
  }, [setStopProcess?.isSuccess]);

  socket.on(`${WebsocketKey.PAUSED_STATE}-${data?.data?.deviceId}-${data?.data?.processId}`, (message: any) => {
    if (
      data?.data?.processId === message?.data?.processId &&
      data?.data?.deviceId === message?.data?.deviceId
    ) {
      dispatch(setIsPaused(false));
      dispatch(setPausedData(message));
      console.log("DISPATCHING 5")
      dispatch(setProcessState({
        processId: data?.data?.processId,
        deviceId: data?.data?.deviceId,
        state: "resume",
    }))
    }
    console.log(message, "pause event");
  });

  const processNormalButton = () => {
    return (
      <>
        <Col md={12} sm={12} lg={12}>
        {(currentProcessState?.state == "resume" || currentProcessState?.state == "pause-processing" ) ? 
          <UIsecondaryButton onPress={() => exitButton()}>EXIT</UIsecondaryButton> : <></>}
        </Col>
        <Col md={12} sm={12} lg={12}>
          {(currentProcessState?.state == "resume" || currentProcessState?.state == "pause-processing") ?
            <UIbutton type="info" size="sm" onPress={onResumeClick}>
              RESUME
            </UIbutton> : 
            ((data?.data?.isReadyForProcessing === "start") || (data?.data?.isReadyForProcessing === "resume") || (currentProcessState?.state === "pause")) ?
              <UIbutton type="info" size="sm" onPress={onPauseClick}>
                PAUSE
              </UIbutton> :
              <UIbutton type="info" size="sm" onPress={onStartClick}>
                START
              </UIbutton>}
        </Col>
      </>)
  }

  
  const getProcessStatus = () => {
    if (
      setStartProcess?.isSuccess ||
      data?.data?.isReadyForProcessing === "pause" ||
      currentProcessState?.state === "pause" ||
      currentProcessState?.state === "resume" ||
      data?.data?.isReadyForProcessing === "resume" ||
      data?.data?.isReadyForProcessing === "start" || 
      data?.data?.isReadyForProcessing === "pause-processing" ||
      currentProcessState?.state === "pause-processing"
    ) {
      return true;
    } else {
      return false;
    }
  };

  //preview function
  const imageForProcessAction = () => {
    return steps?.map((step: any, index: any, id: any) => {
      return (
        <>
          {processName == ProcessName.CHECK_STOCK ? (
            <Col key={id} span={6}>
              <div className={`stepBox ${index === stepIndex && "active"}`}>
                <div
                  className={`icon ${
                    getProcessStatus() && index === stepIndex ? "" : ""
                  }`}
                >
                  <img
                    className={
                      getProcessStatus() && index === stepIndex 
                        ? "scanTag colorIcon pulse"
                        : "scanTag"
                    }
                    src={stepProcess[step?.name]}
                    alt="uhf"
                  />
                </div>
              </div>
            </Col>
          ) : (
            <Col key={id}>
              <div className={`stepBox ${index === stepIndex && "active"}`}>
                <div className={`icon ${getProcessStatus() ? "pulse" : ""}`}>
                  <img
                    className={
                      getProcessStatus() ? "scanTag colorIcon" : "scanTag"
                    }
                    src={stepProcess[step?.name]}
                    alt="uhf"
                  />
                </div>
              </div>
            </Col>
          )}
        </>
      );
    });
  };

  const exitButton = () => {
    setExitModal(true);
  };

  const onExitModalClose = () => {
    setExitModal(false);
  };

  const onPauseModalCancel = () => {
    setPauseModal(false)
  }

  var CurrentDate = moment().format("hh:mm:ss A");

  console.log(getDeviceStatusByMac?.data?.result[0]?.status, "devicestatus");

  const getStepLoopAlignment = () => {
    let alignNumber = (window.innerWidth * 4.263) / 100;
    let width = (window.innerWidth * 8.68) / 100;
    let arrow = (window.innerWidth * 4.81) / 100;
    console.log(">>>>>>>>>>>> window width", window.innerWidth, {
      alignNumber,
      width,
      arrow,
    });

    return {
      alignNumber,
      width,
      arrow,
    };
  };

  const getStepArrowMarker = () => {
    return processName == ProcessName.CHECK_STOCK ? (
      <>
        <span
          className="loopborder1"
          style={{
            left: getStepLoopAlignment().alignNumber,
            width:
              steps?.length === 1
                ? `calc(100% - 45px)`
                : `calc(100% - ${getStepLoopAlignment().width}px)`,
          }}
        ></span>
        {steps?.length === 1 ? (
          <span
            className="loopborder"
            style={{ right: 0, width: `calc(100% - ${(distance + 1) * 45}px)` }}
          ></span>
        ) : (
          <span
            className="loopborder"
            style={{
              right: getStepLoopAlignment().alignNumber,
              width: `calc(100% - ${getStepLoopAlignment().width}px)`,
            }}
          ></span>
        )}
        <span
          className="looparrow"
          style={{ right: `calc(100% -  ${getStepLoopAlignment().arrow}px)` }}
        ></span>
      </>
    ) : (
      <>
        <span
          className="loopborder1"
          style={{
            left: 45,
            width:
              steps?.length === 1 ? `calc(100% - 45px)` : `calc(100% - 90px)`,
          }}
        ></span>
        {steps?.length === 1 ? (
          <span
            className="loopborder"
            style={{ right: 0, width: `calc(100% - ${(distance + 1) * 45}px)` }}
          ></span>
        ) : (
          <span
            className="loopborder"
            style={{
              right: 45,
              width: `calc(100% - 2*${(distance + 1) * 45}px)`,
            }}
          ></span>
        )}
        <span
          className="looparrow"
          style={{ right: `calc(100% - 2*${(distance + 1) * 45}px + 39px)` }}
        ></span>
      </>
    );
  };

  const getUpperRowStepper = () => {
    return processName == ProcessName.CHECK_STOCK ? (
      <Col span={24}>
        <Row>
          {steps?.map((step: any, id: any) => {
            return (
              <Col span={6} className="upperRow">
                <span className="spanTag slice" key={id}>
                  {step?.name}
                </span>
              </Col>
            );
          })}
        </Row>
      </Col>
    ) : (
      <Col className="upperRow" span={24}>
        {steps?.map((step: any, id: any) => {
          return (
            <span className="spanTag slice" key={id}>
              {step?.name}
            </span>
          );
        })}
      </Col>
    );
  };

  const getClassNameForSteps = () => {
    let className = "";
    processName == ProcessName.CHECK_STOCK
      ? (className = "justify-content-center processStepsRow processCR")
      : (className = "justify-content-center processStepsRow");
    return className;
  };

  const getTimezone = (value:any) => {
    const date = new Date(value); // Use a dummy date to set time
    const options:any = { hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };

    let formattedDate = date.toLocaleString('en-US', {timeZoneName: "shortGeneric",});
    const [datePart, timePart] = formattedDate.split(', ');

    // Format the date part as MM-DD-YYYY
    const [month, day, year] = datePart.split('/');
    const formattedDateString = `${month}-${day}-${year}`;

    // Combine the formatted date with the time and timezone abbreviation
    return `${timePart}`;
  };
  const [disconnect, setDisconnect] = useState("Online")
  socket.on("disconnect",(reason:any)=>{
    console.log("<<<< disconnedteddd")
    setDisconnect("Offline")
    socket.auth = { userId: user?.userId, tenantId: tenantId }
      socket.connect()
  })
  socket.on("connect",()=>{
    setDisconnect("Online")
  })

  return (
      <>
        <Row>
          <Col md={13} lg={15} xl={15}>
            <Row>
              <Col span={24} className="activeContent">
                <b>Process Name:</b>{" "}
                {data?.data?.processName
                  ? data?.data?.processName
                  : data?.data?.name}
              </Col>
              <Col span={24} className="activeContent margin">
                <b>Site:</b> {data?.data?.siteName}
              </Col>
              <Col span={24} className="activeContent margin">
                <b>Zone:</b> {data?.data?.zoneName}
              </Col>
              <Col span={24} className="activeContent margin">
                <b>Device Name:</b> {data?.data?.deviceName}
              </Col>
            </Row>
          </Col>
          <Col md={11} lg={9} xl={9}>
            <Row gutter={10}>
              
              {
                processNormalButton()
                }
            </Row>
          </Col>
        </Row>
        <hr className="hr" />
        <Row className="deviceImgCard">
          <Col span={24} className="deviceScanTag">
            <Row>
              <Col
                span={24}
                className={
                  getDeviceStatusByMac?.data?.result[0]?.status ===
                    "connected" ||
                  getDeviceStatusByMac?.data?.result[0]?.status === "running"
                    ? "deviceCardDetail colorOnline"
                    : "deviceCardDetail colorOffnline"
                }
              >
                {getDeviceStatusByMac?.data?.result[0]?.status ===
                  "connected" ||
                getDeviceStatusByMac?.data?.result[0]?.status === "running"
                  ? "DEVICE ONLINE"
                  : "DEVICE OFFLINE"}
              </Col>
              <Col span={24} className="deviceCardDetail">
                {getDeviceStatusByMac?.data?.result[0]?.status ===
                  "connected" ||
                getDeviceStatusByMac?.data?.result[0]?.status === "running" ? (
                  <UIImage
                    src={officeDevice}
                    alt="officedevice"
                    height="60%"
                    width="60%"
                  />
                ) : (
                  <UIImage
                    src={offlineDevice}
                    alt="offlineDevice"
                    height="60px"
                    width="60px"
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <section className="stepswrapper">
              <Row gutter={[20, 20]}>
                {getUpperRowStepper()}
                <Col span={24}>
                  <Row gutter={[20, 20]} className={getClassNameForSteps()}>
                    {getStepArrowMarker()}
                    {imageForProcessAction()}
                  </Row>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={12} className="timeStatus">
            <img src={syncClock} className="syncclock" alt="clock" />
            <span className="currentTime">Current time: {getTimezone(moment().format("YYYY-MM-DD hh:mm:ss A"))}</span>
            </Col>
            <Col span={12} key={disconnect} className="onlineoffline">
            <span>
            <UIImage
                src={disconnect === "Online" ? onlineLog : offlineLog}
                width="50px"
                height="50px"
              />
              <span
                className={
                  disconnect === "Online" ? "online synced" : "offline synced"
                }
              >
                <b>{disconnect}</b>
              </span>
            </span>
          </Col>
        </Row>
        <UIConfirmModal
          key="selectSiteDevice"
          className="siteDeviceform"
          visible={exitModal}
          cancelSiteButton={onExitModalClose}
          cancelButton={ModalButton.CANCEL}
          primaryText={ModalPrimaryText.PROCESS_EXIT}
          confirmButton={ModalButton.CONFIRM}
          type={ModalType.WARN}
          confirmCallback={onStopClick}
        />
        <UIConfirmModal
          key="selectSiteDevice"
          className="siteDeviceform"
          visible={pauseModal}
          cancelSiteButton={onPauseModalCancel}
          cancelButton={ModalButton.CANCEL}
          primaryText={ModalPrimaryText.PAUSE_PROCESS}
          confirmButton={ModalButton.CONFIRM}
          type={ModalType.WARN}
          confirmCallback={onPauseConfirmClick}
        />
      {data?.data?.deviceId === pausedData?.data?.deviceId &&
        data?.data?.processId === pausedData?.data?.processId}
      {
        <ProcessPausedModal
          errorDetail={pausedData?.data}
          openAlert={currentProcessState?.state == "pause-processing"}
        />
      }
    </>
  );
};
